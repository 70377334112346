import axios from 'axios';

const url = 'https://api.appduvido.com.br';

const Api = axios.create({
  baseURL: url,
  headers: {
    Authorization: `Bearer CMS_DVD_TK`,
  },
});

export default Api;