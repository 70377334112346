import React, { useEffect, useState } from 'react';
import './LandingPage.css';
import appleStore from '../../imgs/appstore.png';
import playStore from '../../imgs/googleplay.png';
import { getInvitedChallenge } from '../../services/challenge';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

export const ChallengeImage = 'https://duvido-app-images.s3.amazonaws.com/challenge';
export const ChallengeVideo = 'https://iframe.videodelivery.net/{VIDEO_ID}';

const difficulty = (value = '') => {
  switch (value) {
    case 'EASY':
      return 'Dificuldade fácil';
    case 'MEDIUM':
      return 'Dificuldade média';
    case 'HARD':
      return 'Dificuldade dificil';
  }
};

const difficultyColor = (value = '') => {
  switch (value) {
    case 'EASY':
      return 'facil';
    case 'MEDIUM':
      return 'media';
    case 'HARD':
      return 'dificil';
  }
};

function LandingPage() {
  const [challenge, setChallenge] = useState<any>({});
  const { id } = useParams<any>();
  const shareUrl = `https://share.appduvido.com.br/challenge/${id}`;

  useEffect(() => {
    async function fetchData() {
      const param = window.location.href.split('challenge/')[1];

      const data = await getInvitedChallenge(param);
      setChallenge(data);
      // setChallenge({
      //   file: 'arquivo',
      //   fileType: 'IMG',
      //   challengeClass: 'Música',
      //   challengeName: 'Nome do Desafio',
      //   challengeDescription: 'esta é a descrição do que deve ser feita no desafio',
      //   challengeDifficulty: 'FACIL',
      //   challengeReward: 20
      // })
    }
    fetchData();
  }, []);

  return (
    <div className='ctn'>
      <Helmet>
        <meta property='og:url' content={shareUrl} />
        <meta name='twitter:url' content={shareUrl} />
      </Helmet>

      {!!challenge.fileType && (
        <>
          {challenge.fileType === 'IMAGE' ? (
            <img src={`${ChallengeImage}/${challenge.file}`} className={'file'} />
          ) : (
            <iframe
              src={`${ChallengeVideo.replace('{VIDEO_ID}', challenge.file)}`}
              allow='accelerometer; gyroscope; encrypted-media; picture-in-picture;'
              allowFullScreen
              className={'file'}
            />
          )}
          <h3 className='class'>{challenge.category.name}</h3>
          <h2 className='title'>{challenge.title}</h2>
          <p className='description'>{challenge.description}</p>
          <div className='reward'>
            <p className={difficultyColor(challenge.difficulty)}>
              {difficulty(challenge.difficulty)}
            </p>
            <div className='div-reward'>
              <p className='text-reward'>Recompensa</p>
              <p className='value-reward'>{challenge.rewardCredit} Créditos</p>
            </div>
          </div>
          <div className='apps'>
            <a
              className='play-store'
              href='https://play.google.com/store/apps/details?id=com.duvido.app'
              target={'_blank'}
              rel='noreferrer'
            >
              <img className='play-store' src={playStore} />
            </a>
            <a
              className='apple-store'
              href='https://apps.apple.com/br/app/app-duvido/id1576606671'
              target={'_blank'}
              rel='noreferrer'
            >
              <img className='apple-store' src={appleStore} />
            </a>
          </div>
        </>
      )}
    </div>
  );
}

export default LandingPage;
