import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

interface ProtectedRouteSpecificProps {
  condition?: boolean;
  redirect?: string;
}

type ProtectedRouteProps = ProtectedRouteSpecificProps & RouteProps;

function ProtectedRoute({ condition, redirect, ...routeProps }: ProtectedRouteProps) {
  if (condition) {
    return <Route {...routeProps} exact />;
  }
  return <Redirect to={{ pathname: redirect || '/challenge/:id' }} />;
}

export default ProtectedRoute;
