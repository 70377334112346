import React from 'react';
import { Switch } from 'react-router-dom';
import LandingPage from '../components/LandingPage';
import ProtectedRoute from '../components/ProtectedRoute';

function ChallengeRouter() {
  return (
    <Switch>
      <ProtectedRoute path="/challenge/:id" condition={true} redirect="/">
        <LandingPage />
      </ProtectedRoute>
    </Switch>
  );
}

export default ChallengeRouter;
